.range {
  margin: 0;
  width: 100%;
  -webkit-appearance: none;
  background-color: transparent;
}

.range:focus {
  outline: none;
}

.range::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  box-shadow: none;
  background-color: var(--ui-border-color-dark);
  border-radius: 0px;
  border: 0px solid var(--ui-border-color-dark);
}

.range::-moz-range-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  box-shadow: none;
  background-color: var(--ui-border-color-dark);
  border-radius: 0px;
  border: 0px solid var(--ui-border-color-dark);
}

.range::-webkit-slider-thumb {
  box-shadow: none;
  border: 0px solid var(--active-color);
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  height: 21px;
  width: 11px;
  border-radius: 11px;
  background: var(--active-color);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -10px;
}

.range::-moz-range-thumb {
  box-shadow: none;
  border: 0px solid var(--active-color);
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  height: 21px;
  width: 11px;
  border-radius: 11px;
  background: var(--active-color);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -10px;
}

.range::-moz-focus-outer {
  border: 0;
}

.range-example {
  background: black;
  height: 2em;
}
