.chat_circle {
  background-color: #eaf4f7;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: fixed;
  right: 25px;
  bottom: 25px;
  cursor: pointer;
  padding: 13px 11px;
}

.feedback_button {
  background-color: #2a8aa7;
  position: fixed;
  right: -30px;
  top: 50%;
  color: white;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px 8px 0px 0px;
  padding: 5px 10px;
  cursor: pointer;
  transform: rotate(270deg);
}
.feedback_textarea {
  width: 100%;
}

/* svg color change */
.homeSelected svg {
  fill: #ce5303 !important;
}
