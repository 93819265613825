.subscribe_input {
  margin-top: 0.8rem;
  background: transparent;
  border: 1px solid white;
  width: 300px;
  border-radius: 8px;
  font-size: 17px;
  outline: none;
  color: white;
  font-family: "DM SANS";
  padding: 6px 10px;
  height: 40px;
}

.subscribe_button {
  min-width: 140px;
  font-weight: 600;
  letter-spacing: 1px;
  border: none;
  outline: none;
  appearance: none;
  background-color: #c9e1e9;
  font-family: "DM SANS";
  font-size: 15px;
  color: #19445e;
  border-radius: 30px;
  padding: 8px 12px;
  margin-left: 10px;
  margin-top: 12px;
  height: 38px;
  user-select: none;
  cursor: pointer;
}

.social_icon {
  background: #c9e1e9;
  margin: 0 4px;
  font-size: 1.5em;
  width: 1.5em;
  text-align: center;
  line-height: 1.5em;
  border-radius: 0.8em;
  cursor: pointer;
  &:hover {
    background: #fff;
    transition: 0.4s ease;
  }
}

.border_subscribe {
  border: 1px solid #c7c7c7;
}
@media screen and (max-width: 768px) {
  .border_subscribe {
    width: 200px;
  }
  .subscribe_button {
    font-size: 13px;
    padding: 8px 10px;
  }
}
