.select-ohif-container .select-ohif {
  display: inline-block;
  font-size: 10pt;
  box-sizing: border-box;
  width: auto;
  transition: all 0.15s ease;
  background-color: var(--input-background-color);
  line-height: 16px;
  color: var(--input-placeholder-color);
  height: 40px;
  margin: 0 5px 20px 5px;
  padding: 0 20px;
  placeholder-color: var(--input-placeholder-color);
  cursor: pointer;
  border: none;
  font-weight: normal;
  border-radius: 4px;
  background-image: none;
}

.select-ohif-container .select-ohif::-ms-expand {
  display: none;
}

.select-ohif-container .select-ohif-label {
  padding: 5px;
}

.select-ohif:focus {
  outline: none;
}

.select-ohif-container .select-ohif option {
  font-weight: normal;
}

/** Compatibility styles for MIP */

.select-ohif {
  display: block;
  /* font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "DM SANS";
  font-size: 0.8em;
  font-weight: 600;
  color: #444;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
