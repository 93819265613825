/* NOTE: the order of these styles DO matter */
/* 
SAJAL changed --datePicker-input-background to  fc-neutral-text-color
reverted back as color not match

*/
/* Will edit everything selected including everything between a range of dates */
.CalendarDay__selected_span {
  background: var(--datePicker-picker-background);
  color: var(--datePicker-picker-text);
  border-color: var(--bg-color-gray);
}

/* Will edit selected date or the endpoints of a range of dates */
.CalendarDay__selected {
  background: var(--datePicker-picker-background);
  color: var(--datePicker-picker-text);
  border-color: var(--bg-color);
}

/* Will edit when hovered over. _span style also has this property */
.CalendarDay__selected:hover,
.CalendarDay__default:hover {
  background: var(--datePicker-picker-background);
  color: var(--datePicker-picker-text);
  border-color: var(--bg-color);
}

/* Will edit when the second date (end date) in a range of dates
is not yet selected. Edits the dates between your mouse and said date */
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: var(--datePicker-picker-background);
  color: var(--bg-color);
  border-color: var(--bg-color-gray);
}

/* EXTERIOR INPUT STYLE */
/* Container - placement SAJAL CHNGED HEIGHT from 40px to 22px*/
.DateRangePicker {
  height: 24px;
  margin: 0 5px 20px 5px;
  cursor: pointer;
  border: none;
  width: 100%;
}
/* Container - visual SAJAL HEIGHT 40px to 44px  added PADDING too*/
.DateRangePickerInput {
  width: calc(100% - 10px); /* Just use padding? */
  background-color: var(--datePicker-input-background);
  border-color: var(--bg-color-gray);
  color: var(--datePicker-picker-text-input);
  height: 44px;
  padding: 0px 10px;
}

.DateRangePickerInput.DateRangePickerInput__withBorder {
  border-radius: 4px;
  background-color: var(--datePicker-input-background);
}

/* Input Container */
.DateInput {
  width: 97px;
  height: 38px;
  border-radius: 4px;
  background-color: var(--datePicker-input-background);
}

/* Actual Input Element */
.DateInput > .DateInput_input {
  border-color: transparent;
  background-color: transparent;
  color: var(--datePicker-picker-text-input);
  height: 40px;
  font-size: 10pt;
  padding: 0;
}

/* PRESETS */
.PresetDateRangePicker_panel {
  display: flex;
  justify-content: space-between;
}

.PresetDateRangePicker_button {
  margin: 0;
  padding: 4px 8px;
  color: var(--bg-color);
  background: var(--datePicker-picker-text-input);
}

.PresetDateRangePicker_button:hover {
  background: var(--datePicker-input-background);
  color: var(--datePicker-picker-text-input);
}

.CalendarDay__blocked_out_of_range:hover {
  background: #fff;
  cursor: not-allowed;
  color: var(--datePicker-picker-text-disabled);
}

.DateRangePicker_select {
  background-color: white;
  color: var(--datePicker-picker-text);
  border: 1px solid var(--bg-color);
  border-radius: 4px;
  display: inline-block;
  padding: 3px 20px 3px 10px;
  font-size: 14px;
  cursor: pointer;
  appearance: none;

  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 11px) 11px, calc(100% - 6px) calc(11px);
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
}

.DateRangePicker_select:hover {
  border-color: var(--bg-color);
}
