.dd-menu {
  float: left;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
}

.dd-title {
  font-size: 15px;
}

.dd-menu-toggle {
  display: inline-block;
}

.dd-caret-down {
  display: inline-block;
  width: 0;
  height: 0;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

.dd-menu-list {
  position: absolute;
  top: 100%;
  margin-top: 10px;
  background: white;
  z-index: 999;
  border: 1px solid gray;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  overflow: hidden;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

.dd-menu-list.open {
  display: inline-block;
}

.dd-menu-list.left {
  left: 0;
}

.dd-menu-list.right {
  right: 0;
}

.dd-menu-list.center {
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

.dd-item {
  display: flex;
  color: var(--text-color-active);
  padding: 10px 15px;
  border-bottom: 1px solid #ccc;
}

.dd-item:hover {
  text-decoration: none;
  background: #eee;
}

.dd-item:last-child {
  border-bottom: none;
}

.dd-item-icon {
  margin-right: 10px;
  margin-top: 2px;
}
