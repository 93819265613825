.ToolbarRow {
  /* border-bottom: var(--ui-border-thickness) solid var(--ui-border-color);*/
  flex: 0 0 auto;
  height: var(--toolbar-height);
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
  transition: height 300ms ease;
  width: 100%;
  display: flex;
  align-items: center;
  /*background-color: var(--bg-color-lightest);*/
}

ToolbarRow.expanded {
  height: calc(var(--toolbar-height) + var(--toolbar-drawer-height));
}
