html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "DM SANS";
  /* font-family: Roboto, OpenSans, HelveticaNeue-Light, Helvetica Neue Light,
    Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif; */
}

body {
  font-size: 14px;
  line-height: 1.42857143;
}

#root .fade-enter {
  opacity: 0;
}

#root .fade-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}

#root .fade-exit {
  opacity: 1;
}

#root .fade-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
