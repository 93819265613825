// Mixin Import
@import "./mixin";
@import "./colorBase";

$FontCount: 100, 200, 300, 400, 500, 600, 700, 800, 900;

$BorderCount: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
  19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
  39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50;

@each $fontValue in $FontCount {
  .font-weight-#{$fontValue} {
    font-weight: $fontValue !important;
  }
}

@each $borderValue in $BorderCount {
  .radius-#{$borderValue} {
    border-radius: $borderValue + px !important;
  }
}

// .meta-height-100 {
//   height: 100% !important;
// }

.text-black {
  color: #000 !important;
}

// .fieldDateTime {
//   padding-left: 9px;
// }

// Pointer Cursor
.cursorPointer {
  cursor: pointer !important;
}

// Overflow Visible
.meta-overflow-visible {
  overflow: visible !important;
}

// Height 100%
// .meta-height-vh-full {
//   min-height: 100vh !important;
// }
// @media screen and (min-width: 2400px) {
//   .meta-height-vh-full {
//     min-height: 100% !important;
//   }
// }

// Background Transparent
// .backgroundTransparent {
//   background: transparent !important;
// }

// Error Message
.error-msg {
  color: red;
  font-size: 12px;
  font-weight: 400;
}
// Error Message
.error-msg-white {
  color: white;
  font-size: 12px;
  font-weight: 400;
}

.error-msg-blue {
  color: #94c4d2;
  font-size: 12px;
  font-weight: 400;
}

// Modal Large
.modal-lg {
  display: block;
  width: 80%;
  margin: 0 auto;
}
// Display Hidden
.hidden {
  display: none !important;
}

// started from here

/* text fields theme */

.meta__input {
  width: 95%;
  border: 1px solid #c7c7c7;
  font-family: "DM SANS";
  outline: none;
  border-radius: 8px;
  padding: 10px 10px;
  font-size: 15px;
  &:hover {
    border: 1px solid black;
  }
}

.meta__input__search {
  width: 100%;
  border: 2px solid #c7c7c7;
  border-radius: 50px;
  border: 2px solid #2a8aa7;
  background: rgba(234, 244, 247, 0.25);
  font-family: "DM SANS";
  outline: none;
  // border-radius: 8px;
  margin: 10px 4px 4px 4px;
  padding: 8px 40px;
  font-size: 16px;
  &:hover {
    // border: 1px solid black;
  }
}

.meta__textarea {
  width: 95%;
  border: 1px solid #c7c7c7;
  font-family: "DM SANS";
  outline: none;
  border-radius: 8px;
  padding: 10px 10px;
  font-size: 15px;
  resize: none;
  &:hover {
    border: 1px solid black;
  }
}

.dm_sans {
  font-family: "DM SANS";
}

.bold {
  font-weight: 600;
}

/* for display properties */
.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

/* for text sizing */
.text-12 {
  font-size: 12px;
}
.text-13 {
  font-size: 13px;
}
.text-14 {
  font-size: 14px;
}
.bg-light {
  background: #ffffff !important;
}

.MuiTypography-body1 {
  font-family: DM SANS !important;
}

.myfileupload-buttonbar input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  border: solid transparent;
  border-width: 0 0 100px 200px;
  opacity: 0;
  filter: alpha(opacity=0);
  -o-transform: translate(250px, -50px) scale(1);
  -moz-transform: translate(-300px, 0) scale(4);
  direction: ltr;
  cursor: pointer;
}
.myui-button {
  padding-top: 4px;
  padding-right: 4px;
  position: relative;
  cursor: pointer;
  text-align: center;
  overflow: visible;
  // background-color: red;
  overflow: hidden;
}

.review__card__blue {
  border-radius: 10px;
  border: 1px solid var(--blue-blue-4, #c9e1e9);
  background: rgba(234, 244, 247, 0.5);
  padding: 16px;
  margin-top: 0.5rem;
}

.review__card__green {
  border-radius: 10px;
  border: 1px solid var(--semantic-success, #52a72a);
  background: rgba(229, 242, 223, 0.5);

  padding: 16px;
  margin-top: 0.5rem;
}

.ql-toolbar {
  border-radius: 8px 8px 0px 0px;
}

.ql-container {
  border-radius: 0px 0px 8px 8px;
  height: 300px !important;
}

.highlight_date {
  border-radius: 25px;
  // padding: 10px;
  background-color: #e5f2df !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  // display: none;
  background: none !important;
  abbr {
    display: none;
  }
}
.appointment_calendar
  .react_calendar
  .react-calendar__viewContainer
  .react-calendar__month-view
  .react-calendar__tile {
  flex: 0 1 !important;
  margin: 6px;
  width: 40px !important;
  height: 0px;
  padding: 20px 20px !important;
  overflow: visible !important;
  line-height: 0.1;
  abbr {
    left: -8px;
    position: relative;
  }
}
.react-calendar__tile--active {
  border-radius: 25px;
  background-color: #52a72a !important;
  font-weight: 700;
  color: white !important;
}

.w-full {
  width: 100% !important;
}

.fs-13 {
  font-size: 13px !important;
}
.w-400 {
  width: 400px !important;
}

@media screen and (max-width: 768px) {
  .w-400 {
    width: 250px !important;
  }
  .banner_landing_img {
    width: 100%;
  }
}

.link-blue {
  color: #3030f1 !important;
}

.mobile_menu_item {
  font-size: 18px;
  padding: 16px 30px;
  border-bottom: 1px solid #dfdfdf;
  display: flex;
  justify-content: space-between;
}

.imgContainer {
  overflow: hidden;
  background-position: 0% 0%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.newImageContainer {
  width: 100%;
  margin-top: 4rem;
}

@media screen and (max-width: 400px) {
  .imgContainer {
    background-position: center;
  }
}
