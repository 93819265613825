.find_care_tabs {
  background-color: transparent;
  color: black;
  width: fit-content;
  min-height: 40px !important;
  border-radius: 4px;

  .find_care_tab {
    line-height: 0.5;
    min-height: 36px;
    background-color: transparent;
    color: #404040 !important;
    font-family: "DM SANS";
    text-transform: initial;
    font-size: 15px;
    border-right: 1px solid #c7c7c7;
    border: 1px solid #c7c7c7;
    border-radius: 4px;
    padding: 1.1em 1em;
    outline: none;
    &:nth-child(1) {
      border-radius: 4px 0px 0px 4px;
    }
    &:nth-child(2) {
      border-radius: 0px;
    }
    &:nth-child(3) {
      border-radius: 0px;
    }
    &:nth-child(4) {
      border-radius: 0px 4px 4px 0px;
    }
    &:hover {
      border: 1px solid #404040;
      border-radius: 4px;
      &:nth-child(1) {
        border-radius: 4px 0px 0px 4px;
      }
      &:nth-child(2) {
        border-radius: 0px;
      }
      &:nth-child(3) {
        border-radius: 0px;
      }
      &:nth-child(4) {
        border-radius: 0px 4px 4px 0px;
      }
    }
  }
}

.appointment_tabs {
  margin-top: 1rem;
  background-color: transparent;
  //   border: 1px solid #c7c7c7;
  color: black;
  width: fit-content;
  min-height: 40px !important;
  border-radius: 4px;

  .appointment_tab {
    line-height: 0.5;
    min-height: 36px;
    background-color: transparent;
    color: #404040 !important;
    font-family: "DM SANS";
    text-transform: initial;
    font-size: 15px;
    border-right: 1px solid #c7c7c7;
    border: 1px solid #c7c7c7;
    border-radius: 4px;
    padding: 1.1em 1em;
    outline: none;
    &:nth-child(1) {
      border-radius: 4px 0px 0px 4px;
    }
    &:nth-child(2) {
      border-radius: 0px 4px 4px 0px;
    }
    &:hover {
      border: 1px solid #404040;
      border-radius: 4px;
      &:nth-child(1) {
        border-radius: 4px 0px 0px 4px;
      }
      &:nth-child(2) {
        border-radius: 0px 4px 4px 0px;
      }
    }
  }
}
