.drag-drop-instructions {
  width: 100%;
  height: var(--image_height_dicom);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed rgba(128, 165, 240, 0.35) !important;
}

.drag-drop-instructions h3 {
  color: var(--active-color);
}

.drag-drop-instructions h4 {
  color: var(--text-secondary-color);
}

.link-dialog {
  color: var(--active-color);
  cursor: pointer;
}
