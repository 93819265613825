/* theme buttons */
.meta__button {
  cursor: pointer;
  background-color: #404040;
  color: white;
  padding: 7px 18px;
  border-radius: 20px;
  font-size: 16px;
  font-family: "DM Sans";
  outline: none;
  border: none;
}

.meta__button:hover {
  transition: 0.4s ease;
  background-color: #f36e21;
}

/* theme buttons */
.meta__button_green {
  cursor: pointer;
  background-color: #19445e;
  color: white;
  padding: 7px 18px;
  border-radius: 20px;
  font-size: 15px;
  font-family: "DM Sans";
  outline: none;
  border: none !important;
  font-weight: 600;
  // &:hover {
  //   background: #ff6d0d;
  // }
}
/* theme buttons */
.meta__button_orange {
  cursor: pointer;
  background-color: #ff6d0d;
  color: white;
  padding: 7px 18px;
  border-radius: 20px;
  font-size: 15px;
  font-family: "DM Sans";
  outline: none;
  border: none !important;
  font-weight: 600;
  // &:hover {
  //   background: #ff6d0d;
  // }
}

/* theme buttons */
.meta__button_green_outlined {
  cursor: pointer;
  background-color: white;
  color: #19445e;
  border: 2px solid #19445e;
  padding: 7px 18px;
  border-radius: 20px;
  font-size: 15px;
  font-family: "DM Sans";
  outline: none;
  font-weight: 600;
}
