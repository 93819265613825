$body-color: #ffffff;
$sidebar-width: 220px;
$sidebar-bg-color: #2d8daa; // #185060;
$sidebar-border-color-pc: 55%;

aside.sidebar {
  width: $sidebar-width;
  min-width: $sidebar-width;
  overflow-x: hidden;
  position: relative;
  min-height: calc(100vh - 100px);
  -webkit-font-smoothing: antialiased;

  transition: all 0.5s ease;
  position: relative;
  margin-left: -$sidebar-width;
  padding-top: 0px;

  &.open {
    // transform: none;
    margin-left: 0px;
  }
  &.close {
    margin-left: -0px;
    width: 44px;
    min-width: 44px;
  }

  .sidebar-section-db {
    padding: 0;
    border-bottom: 1px solid
      lighten($sidebar-bg-color, $sidebar-border-color-pc);
  }
  .sidebar-section {
    padding: 1.2rem 0;
    border-bottom: 1px solid
      lighten($sidebar-bg-color, $sidebar-border-color-pc);
  }

  .sidebar-profile {
    .picture-wrapper {
      width: 80px;
      height: 80px;
      overflow: hidden;
      border: 1px solid lighten($sidebar-bg-color, $sidebar-border-color-pc);
    }

    .profile-details {
      .name {
        color: $sidebar-bg-color;
      }
    }
  }

  .sidebar-menu {
    ul#menu {
      list-style-type: none;
      padding: 0;

      li a {
        padding: 0.4rem 0.8rem;
        text-decoration: none;
        color: $sidebar-bg-color;
        i {
          width: 35px;
          height: 35px;
          text-align: center;
          line-height: 35px;
          color: $sidebar-bg-color;
          font-size: 1rem;
          margin-right: 0.5rem;
        }

        i.border {
          border: 1px solid lighten($sidebar-bg-color, 8%) !important;
        }

        .menu-text {
          font-size: 0.9rem;
        }

        &:hover {
          color: lighten($sidebar-bg-color, 40%);
          background: lighten($sidebar-bg-color, 3%);

          i {
            color: lighten($sidebar-bg-color, 90%);
          }
        }
      }
    }
  }
}

.toggleMenu {
  position: absolute;
  margin: 22px 13px;
  cursor: pointer;
}

.toggleMenuX::after {
  content: " D";
  font-weight: "400";
  color: $sidebar-bg-color;
}

.hoversidebar {
  width: $sidebar-width;
}

.toggleMenu .tooltiptext {
  visibility: hidden;
  width: 120px;
}
.toggleMenu:hover .tooltiptext {
  visibility: visible;
}
.toggleMenu:hover {
  content: " Menu";
}
