:root {
  --sidebar_width: 240px;
  --rightbar_width: 400px;
  --sidebar_width_dicom: 160px;
  --rightbar_width_dicom: 320px;
  --image_height_dicom: 200px;
  --sidebar-bg-color: #2d8daa;
  --bg-color: #2a8aa7;
  --bg-color-gray: #eff0f3;
  --bg-color-dark-gray: #808080;

  --bg-color-vdark: #083848;
  --bg-color-dark: #0d6d8a;
  --bg-color-light: #5dbdda;
  --bg-color-lighter: #b9e0ec;
  --bg-color-lightest: #e1f2f7;

  --color-highlight: #ff8800;
  --color-highlight-dark: #ff7700;
  --color-highlight-light: #ffdd88;

  --bg-color-cal: var(--bg-color);
  --bg-color-read: #444444;

  --fc-border-color: var(--bg-color-lighter);
  --fc-daygrid-event-dot-width: 5px;

  --fc-small-font-size: 0.9em;
  --fc-page-bg-color: rgba(108, 108, 108, 0.03);
  --fc-neutral-bg-color: rgba(108, 108, 108, 0.03);
  --fc-neutral-text-color: var(--bg-color-dark-gray);

  --fc-button-text-color: #fff;
  --fc-button-bg-color: var(--bg-color-cal);
  --fc-button-border-color: var(--bg-color-cal);
  --fc-button-hover-bg-color: var(--color-highlight);
  --fc-button-hover-border-color: var(--color-highlight);
  --fc-button-active-bg-color: var(--bg-color-light);
  --fc-button-active-border-color: var(--bg-color-light);

  --fc-event-bg-color: var(--fc-neutral-bg-color);
  --fc-event-border-color: var(--bg-color-lightest);
  --fc-event-text-color: blue;
  --fc-event-selected-overlay-color: var(--color-highlight-light);

  --fc-more-link-bg-color: #d0d0d0;
  --fc-more-link-text-color: inherit;

  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;

  --fc-non-business-color: rgba(215, 215, 215, 0.3);
  --fc-bg-event-color: rgb(225, 247, 221); /*rgb(143, 223, 130) */
  --fc-bg-event-opacity: 0.3;
  --fc-highlight-color: rgba(188, 232, 241, 0.3);
  --fc-today-bg-color: rgba(255, 220, 140, 0.2);
  --fc-now-indicator-color: red;

  --fc-list-event-dot-width: 10px;
  --fc-list-event-hover-bg-color: #f4f4f4;
}

@font-face {
  font-family: "DM Sans";
  src: url("../public/DM_Sans/DMSans-VariableFont_opsz,wght.ttf")
      format("woff2"),
    url("../public/DM_Sans/DMSans-Italic-VariableFont_opsz,wght.ttf")
      format("woff2");
  font-weight: normal;
  font-style: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

Icon svg {
  stroke: var(--bg-color);
}

.fc .fc-toolbar-title {
  font-size: 14px;
  display: inline;
  margin: 0;
}
.fc-header-toolbar-title {
  color: var(--bg-color);
  font-size: 14px;
}

.fc-header-title {
  color: var(--bg-color);
  font-size: 14px;
}

.fc-title {
  font-size: 0.9em;
}

.fc {
  width: 100%;
  margin: 0 auto;
}

.fc td.fc-sun {
  background: red;
}

.fc-past {
  background-color: silver;
}

.fc-list-view .fc-scroller {
  height: auto;
}

.fc .fixed-header .header {
  left: 0;
  position: sticky;
  right: 0;
  top: 0;
}
.fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky > * {
  top: 57px;
}

@media (max-width: 600px) {
  .fc-toolbar.fc-header-toolbar {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
  }
  .fc-toolbar-chunk {
    display: table-row;
    text-align: right;
    padding: 5px 0;
    width: 100%;
  }
  .fc-toolbar.fc-header-toolbar .fc-left {
    order: 3;
    align-items: flex-end;
  }
  .fc-toolbar.fc-header-toolbar .fc-center {
    order: 1;
    align-items: end;
  }
  .fc-toolbar.fc-header-toolbar .fc-right {
    order: 2;
    align-items: flex-end;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: "DM SANS";
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  /* font-family: "Exo", sans-serif; */
  font-weight: 700;
  color: #222;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 44px;
}

h3,
.h3 {
  font-size: 1.5rem;
}

h4,
.h4 {
  font-size: 1.3rem;
  line-height: 30px;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

p {
  line-height: 30px;
}

.navbar {
  z-index: 9999;
  background: #ffffff;
}

.navbar-toggle .icon-bar {
  background: var(--bg-color);
}

input[type="email"],
input[type="password"],
input[type="text"],
input[type="tel"] {
  box-shadow: none;
  height: 45px;
  outline: none;
  font-size: 14px;
}

input[type="search"] {
  box-shadow: none;
  height: 20px;
  outline: none;
  font-size: 11px;
  border-radius: 10px;
  background: #b0ddea;
  color: var(--bg-color-dark-gray);
  border: 1px solid #a8dcf3;
  padding: 3px 5px;
  margin-left: 15px;
}

input[type="search"]:focus {
  box-shadow: none;
  color: #57b0cd;
  background: #ffffff;
  border: 1px solid #a8dcf3;
}

.form-control {
  box-shadow: none;
  border-radius: 0;
}

.form-control:focus {
  box-shadow: none;
  border: 1px solid var(--bg-color);
}

.py-7 {
  padding: 7rem 0px;
}

.btn-full-width {
  width: 100%;
}

.btn-sm {
  height: 35px;
  display: inline-block;
  font-size: 14px;
  font-size: 0.8125rem;
  font-weight: 700;
  padding: 0.2rem 0.4rem;
  /* font-family: "Exo", sans-serif; */
  border-radius: 4px;
  border: 1px solid transparent;
  transition: all 0.35s ease;
  cursor: pointer;
}

.btn {
  display: inline-block;
  font-size: 14px;
  font-size: 0.8125rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  padding: 0.5rem 1rem;
  /* font-family: "Exo", sans-serif; */
  border-radius: 4px;
  border: 2px solid transparent;
  transition: all 0.35s ease;
  cursor: pointer;
}

.btn.btn-icon i {
  border-left: 1px solid rgba(255, 255, 255, 0.09);
  padding-left: 15px;
}

.btn.btn-icon-s i {
  border-left: 1px solid rgba(255, 255, 255, 0.09);
  padding-left: 5px;
}

.btn:focus {
  outline: 0px;
  box-shadow: none;
}

.btn-main {
  background: var(--bg-color);
  color: #fff;
  border-color: var(--bg-color);
  text-transform: uppercase;
}

.btn-main-2 {
  background: var(--color-highlight);
  color: #fff;
  border-color: #ff8800;
  text-transform: uppercase;
}

.btn-main-2-inv {
  background: var(--bg-color);
  color: #fff;
  border-color: var(--bg-color);
  text-transform: uppercase;
}

.btn-main-gray-inv,
.btn-main-gray {
  background: var(--bg-color-dark-gray);
  color: #fff;
  border-color: var(--bg-color-dark-gray);
  text-transform: uppercase;
}

.btn-main:hover {
  background: #ee8800;
  border-color: #ec8400;
  color: #fff;
}

.btn-main-2:hover,
.btn-main-gray:hover {
  background: var(--bg-color);
  color: #fff;
  border-color: var(--bg-color);
  text-decoration: none;
}

.btn-main-2-inv:hover,
.btn-main-gray-inv:hover {
  background: var(--color-highlight);
  color: #fff;
  border-color: #ff8800;
  text-decoration: none;
}

.btn-solid-border {
  border: 2px solid var(--bg-color);
  background: transparent;
  color: var(--bg-color);
}

.btn-solid-border:hover {
  border: 2px solid var(--bg-color);
  color: #fff;
  background: var(--bg-color);
}

.btn-solid-border:hover.btn-icon i {
  border-left: 1px solid rgba(255, 255, 255, 0.09);
}

.btn-solid-border.btn-icon i {
  border-left: 1px solid rgba(0, 0, 0, 0.09);
}

.btn-transparent {
  background: transparent;
  color: #222;
  border-color: var(--bg-color);
}

.btn-transparent:hover {
  background: var(--bg-color);
  color: #fff;
}

.btn-white {
  background: #fff;
  border-color: #fff;
  color: #222;
}

.btn-white:hover {
  background: var(--bg-color);
  color: #fff;
  border-color: var(--bg-color);
}

.btn-solid-white {
  border-color: #fff;
  color: #fff;
}

.btn-solid-white:hover {
  background: #fff;
  color: #222;
}

.btn-round {
  border-radius: 4px;
}

.btn-round-full {
  border-radius: 40px;
}

.btn.active:focus,
.btn:active:focus,
.btn:focus {
  outline: 0;
}

.p5 {
  padding: 5px;
}
.p10 {
  padding: 10px;
}
.py5 {
  padding-left: 5px;
  padding-right: 5px;
}
.py10 {
  padding-left: 10px;
  padding-right: 10px;
}
.pY5 {
  padding-top: 5px;
  padding-botom: 5px;
}
.pY10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.m5 {
  margin: 5px;
}
.my5 {
  margin-left: 5px;
  margin-right: 5px;
}
.m10 {
  margin: 10px;
}
.my10 {
  margin-left: 10px;
  margin-right: 10px;
}

.w50 {
  width: 50px;
}

.w100 {
  width: 100px;
}

.bg-white {
  background: #ffffff;
}

.bg-gray {
  background: var(--bg-color-gray);
}

.bg-gray-dark {
  background: #dfe0e3;
}
.bg-standard {
  background: var(--bg-color);
}
.bg-standard-light {
  background: #9cd5e4;
}
.bg-standard-lighter {
  background: #ace5f4;
}
.bg-standard-lightest {
  background: #edf4fa;
}

.bg-standard-offwhite {
  background: #fdfdfe;
}

.bg-selected {
  background: #edf4fa;
}

.bg-primary {
  background: var(--bg-color);
}

.bg-primary-dark {
  background: #152440;
}

.bg-primary-darker {
  background: #090f1a;
}

.bg-dark {
  background: #222;
}

.bg-gradient-menu {
  /* margin: 0 0.5rem; */
  /* background-image: url("./assets/images/bg/bg-menu.png");
  background-repeat: repeat-x; */
}

.bg-gradient {
  background-image: linear-gradient(
    145deg,
    rgba(19, 177, 205, 0.95) 0%,
    rgba(152, 119, 234, 0.95) 100%
  );
  background-repeat: repeat-x;
}

.bg-gradientold {
  background-image: linear-gradient(
    145deg,
    rgba(19, 177, 205, 0.95) 0%,
    rgba(152, 119, 234, 0.95) 100%
  );
  background-repeat: repeat-x;
}

.scrolly {
  overflow-y: scroll;
  margin-bottom: 16px;
}
.section {
  padding: 100px 0;
}

.section-sm {
  padding: 70px 0;
}

.section-bottom {
  padding-bottom: 100px;
}

.section-db {
  padding: 20px 0;
}

.section-bottom-db {
  padding-bottom: 10px;
}

.subtitle {
  color: var(--bg-color);
  font-size: 14px;
  letter-spacing: 1px;
}

.notes2 {
  columns: 400px 2;
}

.notes3 {
  columns: 400px 3;
}

.dropdown-menu {
  display: block;
  max-height: 28px;
  width: 100%;
  overflow-y: hidden;
}

.TitleHeading1 {
  padding-top: 40px;
  color: #6f8ba4;
  /* text-transform: uppercase; */
  font-size: 1.3rem;
  line-height: 30px;
  font-family: "DM SANS";
  /* font-family: "Roboto Regular", "sans-serif"; */
  font-weight: 500;
  letter-spacing: 6px;
}

.TitleHeading2 {
  color: #75a4da;
  font-size: 2rem;
  line-height: 38px;
  /* font-family: Arial, "Exo", "sans-serif"; */
  font-weight: 800;
  letter-spacing: 3px;
}

.TitleHeading3 {
  color: #9cd5e4;
  font-size: 2.6rem;
  /* line-height: 43px; */
  font-family: "Arial", "Exo", "sans-serif";
  font-weight: 600;
  letter-spacing: 2px;
}

.TitleHeading4 {
  color: #8fabc4;
  font-size: 1.2rem;
  line-height: 26px;
  font-family: "DM SANS";
  /* font-family: "Roboto", "sans-serif"; */
  font-weight: 400;
  letter-spacing: 6px;
}

.TitleHeading5 {
  color: #8fabc4;
  font-size: 1.2rem;
  line-height: 26px;
  font-family: "DM SANS";
  /* font-family: "Roboto", "sans-serif"; */
  font-weight: 400;
  letter-spacing: 6px;
}

.TitleMenu {
  text-transform: uppercase;
  letter-spacing: 5px;
}
.rs {
}
.rs:before {
  content: "\f156";
  color: var(--bg-color);
}

.rss {
}
.rss:before {
  content: "\f143";
  color: var(--bg-color);
}

.reply {
}
.reply:before {
  content: "\f112";
  color: var(--bg-color);
}
.reply:after {
  content: "";
  color: var(--bg-color);
}

/*0 metauser  1 metamd   2 metaorg   3 metapharma 4 metaothers*/

.online,
.online0,
.online1,
.online2,
.online3,
.online4 {
  width: 32px;
  height: 30px;
  background: var(--color-highlight);
  shape-outside: circle();
  clip-path: circle();
}

.offline,
.offline0,
.offline1,
.offline2,
.offline3,
.offline4 {
  width: 32px;
  height: 30px;
  background: #e0e0e0;
  shape-outside: circle();
  clip-path: circle();
}
.online,
.offline,
.online4,
.offline4 {
  padding: 8px;
}

.online0,
.offline0,
.online1,
.offline1,
.online2,
.offline2,
.online3,
.offline3 {
  padding: 3px;
}
.online0:after,
.offline0:after {
  content: "U";
  display: inline;
}
.online1:after,
.offline1:after {
  content: "+";
  font-size: 18px;
  display: inline;
}
.online2:after,
.offline2:after {
  content: "H";
  display: inline;
}

.online3:after,
.offline3:after {
  content: "P";
  display: inline;
}

.overlay:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  background: var(--bg-color);
}

.overly-2 {
  position: relative;
}

.overly-2:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.no-spacing {
  letter-spacing: 0px;
}

a {
  color: #222;
  text-decoration: none;
  transition: all 0.35s ease;
}

a:focus,
a:hover {
  color: var(--color-highlight);
  text-decoration: none;
}

a:focus {
  outline: none;
}

.content-title {
  font-size: 40px;
  line-height: 50px;
}

.page-title {
  padding: 120px 0px 70px 0px;
  position: relative;
}

.page-title .block h1 {
  color: #fff;
}

.page-title .block p {
  color: #fff;
}

.page-title .breadcumb-nav {
  margin-top: 60px;
  padding-top: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.06);
}

.slick-slide:focus,
.slick-slide a {
  outline: none;
}

@media (max-width: 480px) {
  h2,
  .h2 {
    font-size: 1.3rem;
    line-height: 36px;
  }
}

.secondary-bg {
  background: var(--bg-color);
}

.section-title {
  margin-bottom: 70px;
}

.section-title-db {
  margin-bottom: 10px;
}

.section-title h2 {
  color: var(--bg-color);
}

.text-vsm {
  font-size: 16px;
  /* font-family: "Exo", sans-serif; */
}
.text-sm {
  font-size: 18px;
  /* font-family: "Exo", sans-serif; */
}
.text-md {
  font-size: 20px;
  /* font-family: "Exo", sans-serif; */
}
.text-lg {
  font-size: 32px;
  /* font-family: "Exo", sans-serif; */
}
@media screen and (max-width: 400px) {
  .text-vsm {
    font-size: 12px;
  }
  .text-sm {
    font-size: 13px;
  }
  .text-md {
    font-size: 15px;
  }
  .text-lg {
    font-size: 22px;
  }
}

@media screen and (max-width: 480px) {
  .text-vsm {
    font-size: 13px;
  }
  .text-sm {
    font-size: 14px;
  }
  .text-md {
    font-size: 17px;
  }
  .text-lg {
    font-size: 22px;
  }
}
@media screen and (max-width: 600px) {
  .text-vsm {
    font-size: 14px;
  }
}
@media screen and (max-width: 900px) {
  .text-vsm {
    font-size: 15px;
  }
  .text-sm {
    font-size: 16px;
  }
  .text-md {
    font-size: 19px;
  }
  .text-lg {
    font-size: 28px;
  }
}

.text-vvsm {
  font-size: 10px;
}

#navbarmain {
  padding: 20px 0px;
}

#navbarmain .nav-link {
  font-weight: 600;
  padding: 10px 15px;
  color: #222;
  /* font-family: "Exo", sans-serif; */
  text-transform: capitalize;
  font-size: 16px;
  transition: all 0.25s ease;
}

.dropdown-toggle::after {
  display: none;
}

.navbar-brand {
}

.dropdown .dropdown-menu {
  position: absolute;
  display: block;
  background: #fff;
  min-width: 240px;
  top: 130%;
  left: 0;
  right: 0px;
  opacity: 0;
  padding: 0px;
  visibility: hidden;
  transition: all 0.3s ease-out 0s;
  border: 0px;
  border-top: 5px solid #e12454;
  border-radius: 0px;
}

.dropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: 115%;
}

.dropdown .dropdown-item {
  padding: 13px 20px;
  border-bottom: 1px solid #eee;
  background: transparent;
  font-weight: 400;
  color: #555;
}

.dropdown .dropdown-item:hover {
  color: #e12454;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

.sticky + .content {
  padding-top: 102px;
}

.header-top-bar-db {
  background: var(--bg-color) url("assets/images/logo-db-sm.png") no-repeat 4px
    50%;
  font-size: 14px;
  padding: 6px 0px 6px 0px;
  z-index: 9999;
}

.header-top-dash {
  background: var(--bg-color);
  font-size: 14px;
  padding: 0.25px 0px;

  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  color: #fff;
}

.header-top-bar {
  background: var(--bg-color);
  font-size: 14px;
  padding: 10px 0px;

  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  color: #fff;
}

.top-bar-info li a {
  color: #fff;
  margin-right: 0px;
}

.top-right-bar a span {
  color: #fff;
  letter-spacing: 1px;
}

.top-right-bar a i {
  color: #fff;
  margin-right: 10px;
}

.bg-1 {
  background-size: cover;
  position: relative;
}

.banner {
  position: relative;
  overflow: hidden;
  background: #fff;
  /*background: url("assets/images/bg/home-bg-20.jpg") no-repeat;*/
  background-size: cover;
  height: 480px;
}

.banner .block {
  padding: 40px 0px 160px;
}

.banner .block h1 {
  font-size: 48px;
  line-height: 0.8;
  letter-spacing: -1.2px;
  text-transform: capitalize;
}

.banner .block h2 {
  margin-top: -10px;
  font-size: 56px;
  line-height: 1;
  letter-spacing: 1px;
  text-transform: capitalize;
}

.usr {
  position: relative;
  overflow: hidden;
  background: #fff;
  background-size: cover;
  height: 480px;
  opacity: 0.99;
}

.org {
  position: relative;
  overflow: hidden;
  background: #fff;
  background-size: cover;
  height: 480px;
  opacity: 0.9;
}

.md {
  position: relative;
  overflow: hidden;
  background: #fff;
  background-size: cover;
  height: 480px;
  opacity: 0.9;
}

.con {
  position: relative;
  overflow: hidden;
  background: #fff;
  background-size: cover;
  height: 480px;
  opacity: 0.9;
}

.dicom {
  position: relative;
  overflow-y: auto;
  background: #fff;
  background-size: cover;
  height: auto;
  width: 100%;
  opacity: 0.99;
}

.dicom-page-nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.dicom-page-nav-item {
  display: block;
  display: inline;
}

.letter-spacing {
  letter-spacing: 2px;
}

.ls5 {
  letter-spacing: 5px;
}

.text-color-read {
  color: var(--bg-color-read);
}
.text-color-read a {
  color: var(--bg-color);
}
.text-color {
  color: var(--bg-color);
}

.text-color a {
  color: var(--bg-color);
}
.text-color:active {
  color: var(--bg-color);
}

.text-color:hover {
  color: var(--color-highlight-dark);
}

.text-color-hover:hover {
  color: var(--color-highlight-light);
}

.text-color-inv {
  color: var(--color-highlight);
}
.text-color-primary {
  color: var(--bg-color);
}
.text-color-white,
.text-color-white a {
  color: #ffffff;
}

.text-color-menu {
  color: #888888;
}
.text-color-menu a {
  color: #888888;
}
.text-color-menu-active {
  color: var(--bg-color-read);
}
.text-color-menu-active a {
  color: var(--bg-color-read);
}
.text-color-menu:hover,
.text-color-menu-active:hover {
  color: var(--bg-color);
}

.text-color-vdark {
  color: #888888;
}
.text-color-gray {
  color: #cccccc;
}
.text-color-dark {
  color: var(--bg-color-dark-gray);
}
.text-color-error {
  color: #ff0000;
}
.text-color-success {
  color: #00ff00;
}
.text-color-primary-off {
  color: #75a4da;
}

.text-color-primary-highlight {
  color: var(--color-highlight);
}

.text-color-h1 {
  color: #75a4da;
}

.text-color-h2 {
  color: #9cd5e4;
}

.text-color-h3 {
  color: #b0ddea;
}

.text-scroll {
  color: #8080ff;
}

.text-embossx {
  text-shadow: 0px -1px 0px rgba(255, 255, 255, 0.5);
}

.text-color-2-bg {
  background: #c8ecff;
}
.text-color-2-bg:focus {
  background: #d2efff;
}
.text-color-2-bg:hover {
  background: #d2efff;
}
.text-color-2-border {
  border: 1px solid #78acc3;
}
.text-color-2 {
  color: #222244; /*#aadcf3;*/
}

.text-color-2 a {
  color: #a8dcf3;
}

.text-color-2:hover a:hover {
  color: #ffd540;
}

.text-color-footer,
.text-color-footer-primary {
  color: #1d7d9a;
}

.text-color-footer a {
  color: #2a8aa7;
}

.text-color-footer:hover {
  color: var(--color-highlight-dark);
}

.divider {
  width: 40px;
  height: 5px;
  background: #e12454;
}

@media (max-width: 480px) {
  .banner .block h1 {
    font-size: 38px;
    line-height: 50px;
  }

  .banner {
    min-height: 400px;
    background: #fff !important;
  }
}

@media (max-width: 400px) {
  .banner .block h1 {
    font-size: 28px;
    line-height: 40px;
  }

  .banner {
    min-height: 400px;
    background: #fff !important;
  }
}

@media (max-width: 768px) {
  .banner .block h1 {
    font-size: 56px;
    line-height: 70px;
  }

  .banner {
    background: #fff !important;
  }
}

@media (max-width: 992px) {
  .banner {
    background: #fff !important;
  }
}

.about-img img {
  border-radius: 5px;
  box-shadow: 0px 0px 30px 0px rgba(0, 42, 106, 0.1);
}

.award-img {
  height: 120px;
  margin-bottom: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: #eff0f3;
}

.appoinment-content {
  position: relative;
}

.appoinment-content img {
  width: 85%;
}

.appoinment-content .emergency {
  position: absolute;
  content: "";
  right: 10px;
  bottom: 20px;
  background: var(--bg-color);
  padding: 48px;
}

.appoinment-content .emergency h2 {
  color: #fff;
}

.appoinment-content .emergency i {
  margin-right: 10px;
  color: rgba(255, 255, 255, 0.7);
}

.appoinment-form {
  margin-top: 40px;
}

.appoinment-form-db {
  margin-top: 10px;
}

.appoinment-form .form-control {
  background: #f4f9fc;
  height: 55px;
  border-color: rgba(0, 0, 0, 0.05);
}

.appoinment-form textarea.form-control {
  height: auto;
}

.client-thumb {
  text-align: center;
}

.features {
  margin-top: -70px;
}

.feature-item {
  flex-basis: 33.33%;
  margin: 0px 10px;
  padding: 40px 30px;
  background-color: #fff;
  border-radius: 15px 15px 15px 15px;
  box-shadow: 0px 0px 30px 0px rgba(0, 42, 106, 0.1);
}

.feature-item .feature-icon i {
  font-size: 50px;
  color: var(--bg-color);
}

.feature-item h4 {
  color: var(--bg-color);
}

.feature-item p {
  font-size: 14px;
}

.feature-section.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.w-hours li {
  padding: 6px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.counter-stat {
  text-align: center;
  padding: 55px 0px 40px 0px;
  position: relative;
}

.counter-stat i {
  display: block;
  color: rgba(255, 255, 255, 0.06);
  font-size: 70px;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  -webkit-transform: translateY(25px);
  transform: translateY(25px);
}

.counter-stat span {
  font-size: 70px;
  color: #fff;
}

.counter-stat p {
  margin-bottom: 0px;
  color: rgba(255, 255, 255, 0.7);
}

.mb--80 {
  margin-bottom: -80px;
}

.service {
  padding-top: 180px;
}

.service-db {
  padding-top: 10px;
}

.service .service-item {
  background: #fff;
  padding: 30px;
  border-radius: 5px;
}

.service-db .service-item-db {
  background: #fff;
  padding: 10px;
  border-radius: 5px;
}

.service .icon {
  float: left;
  margin-bottom: 10px;
}

.service i {
  color: #ff8800;
}

.service h4 {
  padding-left: 20px;
}

.service .content {
  clear: both;
}

.service-block {
  padding: 20px;
  margin-top: 40px;
  border: 1px solid rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 38px rgba(21, 40, 82, 0.07);
}

.service-block-db {
  padding: 20px;
  margin-top: 10px;
  border: 1px solid rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 38px rgba(21, 40, 82, 0.07);
}

.service-block img {
  width: 100%;
  margin-top: -60px;
  border: 5px solid #fff;
}

.department-service {
  margin-bottom: 40px;
}

.department-service-db {
  margin-bottom: 10px;
}

.department-service li {
  margin-bottom: 10px;
}

.department-service li i {
  color: #e12454;
}

.doctors .btn-group .btn {
  border-radius: 0px;
  margin: 0px 2px;
  text-transform: capitalize;
  font-size: 16px;
  padding: 0.6rem 1.5rem;
  cursor: pointer;
}

.doctors .btn-group .btn.active {
  box-shadow: none !important;
  border-color: transparent;
  background: #e12454;
  color: #fff;
}

.doctors .btn-group .btn.focus {
  box-shadow: none !important;
  border-color: transparent;
}

.doctors .btn-group .btn:focus {
  box-shadow: none !important;
  border-color: transparent;
  background: #e12454;
  color: #fff;
}

.doctors .btn-group .btn:hover {
  box-shadow: none !important;
  border-color: transparent;
  background: #e12454;
  color: #fff;
}

.doctors .btn-group > .btn-group:not(:last-child) > .btn,
.doctors .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.doctors .btn-group > .btn:not(:first-child) {
  border-radius: 3px;
}

.doctor-inner-box {
  overflow: hidden;
}

.doctor-inner-box .doctor-profile {
  overflow: hidden;
  position: relative;
  box-shadow: 0px 8px 16px 0px rgba(200, 183, 255, 0.2);
}

.doctor-inner-box .doctor-profile .doctor-img {
  transition: all 0.35s ease;
}

.doctor-inner-box .doctor-profile .doctor-img:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.lh-35 {
  line-height: 35px;
}

.lh-1 {
  line-height: 1;
}
.doctor-info li {
  margin-bottom: 10px;
  color: #222;
}

.doctor-info li i {
  margin-right: 20px;
  color: #e12454;
}

.read-more {
  color: var(--bg-color);
}

@media (max-width: 480px) {
  .doctors .btn-group {
    display: block;
  }

  .doctors .btn-group .btn {
    margin: 8px 3px;
  }
}

@media (max-width: 400px) {
  .doctors .btn-group {
    display: block;
  }

  .doctors .btn-group .btn {
    margin: 8px 3px;
  }
}

@media (max-width: 768px) {
  .doctors .btn-group {
    display: block;
  }

  .doctors .btn-group .btn {
    margin: 8px 3px;
  }
}

.cta {
  background-size: cover;
  position: relative;
}

.cta:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(84, 98, 142, 0.95);
}

.mb-30 {
  margin-bottom: 30px;
}

.cta-section {
  margin-bottom: -80px;
}

.cta-2 {
  background-position: center center;
}

.cta-page {
  background-size: cover;
  position: relative;
}

.testimonial {
  position: relative;
}

.testimonial:before {
  width: 48%;
  height: 100%;
  top: 0;
  left: 0px;
  position: absolute;
  content: "";
}

.testimonial .slick-dots {
  text-align: left;
}

.testimonial-block {
  position: relative;
  margin-bottom: 20px;
}

.testimonial-block p {
  background: #fff;
  font-size: 18px;
}

.testimonial-block .client-info {
  margin-bottom: 20px;
}

.testimonial-block .client-info h4 {
  margin-bottom: 0px;
}

.testimonial-block i {
  font-size: 60px;
  position: absolute;
  right: 46px;
  bottom: 89px;
  opacity: 0.08;
}

.testimonial-block .slick-dots {
  text-align: left;
}

.testimonial-wrap-2 .slick-dots {
  margin-left: -10px;
}

.testimonial-block.style-2 {
  background: #fff;
  padding: 30px;
  margin: 0px 4px;
  margin-bottom: 30px;
  width: auto !important;
}

.testimonial-block.style-2 .testimonial-thumb {
  float: left;
}

.testimonial-block.style-2 .testimonial-thumb img {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  margin-right: 20px;
  margin-bottom: 30px;
  border: 5px solid #eff0f3;
  margin-top: -5px;
}

.testimonial-block.style-2 .client-info p {
  clear: both;
  background: transparent;
}

.testimonial-block.style-2 i {
  bottom: -20px;
  color: #e12454;
  opacity: 0.3;
}

@media (max-width: 480px) {
  .testimonial-wrap {
    margin-left: 0px;
  }

  .testimonial::before {
    display: none;
  }
}

@media (max-width: 400px) {
  .testimonial-wrap {
    margin-left: 0px;
  }

  .testimonial::before {
    display: none;
  }
}

@media (max-width: 768px) {
  .testimonial-wrap {
    margin-left: 0px;
  }

  .testimonial::before {
    display: none;
  }
}

@media (max-width: 992px) {
  .testimonial-wrap {
    margin-left: 0px;
  }

  .testimonial::before {
    display: none;
  }
}

.contact-form-wrap .form-group {
  margin-bottom: 20px;
}

.contact-form-wrap .form-group .form-control {
  height: 60px;
  border: 1px solid #eef2f6;
  box-shadow: none;
  width: 100%;
  background: #f4f9fc;
}

.contact-form-wrap .form-group-2 {
  margin-bottom: 13px;
}

.contact-form-wrap .form-group-2 textarea {
  height: auto;
  border: 1px solid #eef2f6;
  box-shadow: none;
  background: #f4f9fc;
  width: 100%;
}

.social-icons li {
  margin: 0 6px;
}

.social-icons a {
  margin-right: 10px;
  font-size: 18px;
}

.google-map {
  position: relative;
}

.google-map #map {
  width: 100%;
  height: 500px;
}

.mt-90 {
  margin-top: 90px;
}

.contact-block {
  text-align: center;
  border: 5px solid #eef2f6;
  padding: 50px 25px;
}

.contact-block i {
  font-size: 50px;
  margin-bottom: 15px;
  display: inline-block;
  color: #e12454;
}

.blog-item-content h2 {
  font-weight: 600;
  font-size: 38px;
}

.nav-links .page-numbers {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #eee;
  text-align: center;
  padding-top: 13px;
  font-weight: 600;
  margin-right: 10px;
}

.nav-links .page-numbers:hover {
  background: var(--bg-color);
  color: #fff;
}

.nav-links .page-numbers.current {
  background: var(--bg-color);
  color: #fff;
}

.comment-area .comment-thumb {
  margin-right: 20px;
  margin-bottom: 30px;
}

.comment-area h5 {
  font-size: 18px;
  font-weight: 500;
}

.comment-area span {
  font-size: 14px;
}

.posts-nav h6 {
  font-weight: 500;
}

.quote {
  font-size: 22px;
  color: var(--bg-color);
  padding: 40px;
  font-style: italic;
  border-left: 5px solid #e12454;
  margin: 25px 0px;
}

.tag-option a {
  border: 1px solid #eff0f3;
  padding: 6px 12px;
  color: #6f8ba4;
  font-size: 14px;
}

.comment-form .form-control {
  background: #f7f8fb;
  border-radius: 5px;
  border-color: #f7f8fb;
  height: 50px;
}

.comment-form textarea.form-control {
  height: auto;
}

.post.post-single {
  border: none;
}

.post.post-single .post-thumb {
  margin-top: 30px;
}

.post-sub-heading {
  border-bottom: 1px solid #dedede;
  padding-bottom: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 20px;
}

.post-social-share {
  margin-bottom: 50px;
}

.post-comments {
  margin: 30px 0;
}

.post-comments .media {
  margin-top: 20px;
}

.post-comments .media > .pull-left {
  padding-right: 20px;
}

.post-comments .comment-author {
  margin-top: 0;
  margin-bottom: 0px;
  font-weight: 500;
}

smart-column {
}

@media only screen and (max-width: 700px) {
  smart-column {
    width: 100%;
  }
}

.post-comments .comment-author a {
  color: var(--bg-color);
  font-size: 14px;
  text-transform: uppercase;
}

.post-comments time {
  margin: 0 0 5px;
  display: inline-block;
  color: #808080;
  font-size: 12px;
}

.post-comments .comment-button {
  color: var(--bg-color);
  display: inline-block;
  margin-left: 5px;
  font-size: 12px;
}

.post-comments .comment-button i {
  margin-right: 5px;
  display: inline-block;
}

.post-comments .comment-button:hover {
  color: var(--bg-color);
}

.post-excerpt {
  margin-bottom: 60px;
}

.post-excerpt h3 a {
  color: #000;
}

.post-excerpt p {
  margin: 0 0 30px;
}

.post-excerpt blockquote.quote-post {
  margin: 20px 0;
}

.post-excerpt blockquote.quote-post p {
  line-height: 30px;
  font-size: 20px;
  color: var(--bg-color);
}

.comments-section {
  margin-top: 35px;
}

.author-about {
  margin-top: 40px;
}

.post-author {
  margin-right: 20px;
}

.post-author > img {
  border: 1px solid #dedede;
  max-width: 120px;
  padding: 5px;
  width: 100%;
}

.comment-list ul {
  margin-top: 20px;
}

.comment-list ul li {
  margin-bottom: 20px;
}

.comment-wrap {
  border: 1px solid #dedede;
  border-radius: 1px;
  margin-left: 20px;
  padding: 10px;
  position: relative;
}

.comment-wrap .author-avatar {
  margin-right: 10px;
}

.comment-wrap .media .media-heading {
  font-size: 14px;
  margin-bottom: 8px;
}

.comment-wrap .media .media-heading a {
  color: var(--bg-color);
  font-size: 13px;
}

.comment-wrap .media .comment-meta {
  font-size: 12px;
  color: #888;
}

.comment-wrap .media p {
  margin-top: 15px;
}

.comment-reply-form {
  margin-top: 80px;
}

.comment-reply-form input,
.comment-reply-form textarea {
  height: 35px;
  border-radius: 0;
  box-shadow: none;
}

.comment-reply-form input:focus,
.comment-reply-form textarea:focus {
  box-shadow: none;
  border: 1px solid var(--bg-color);
}

.comment-reply-form textarea,
.comment-reply-form .btn-main {
  height: auto;
}

.sidebar-widget {
  margin-bottom: 30px;
  padding-bottom: 35px;
}

.sidebar-widget h5 {
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 15px;
}

.sidebar-widget h5:before {
  position: absolute;
  content: "";
  left: 0px;
  bottom: 0px;
  width: 35px;
  height: 3px;
  background: #e12454;
}

.sidebar-widget.latest-post .media img {
  border-radius: 7px;
}

.sidebar-widget.latest-post .media h6 {
  font-weight: 500;
  line-height: 1.4;
}

.sidebar-widget.latest-post .media p {
  font-size: 12px;
}

.sidebar-widget.category ul li {
  margin-bottom: 10px;
}

.sidebar-widget.category ul li a {
  color: #222;
  transition: all 0.3s ease;
}

.sidebar-widget.category ul li a:hover {
  color: var(--bg-color);
  padding-left: 5px;
}

.sidebar-widget.category ul li span {
  margin-left: 10px;
}

.sidebar-widget.tags a {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.075em;
  line-height: 41px;
  height: 41px;
  font-weight: 500;
  border-radius: 20px;
  color: #666;
  display: inline-block;
  background-color: #eff0f3;
  margin: 0 7px 10px 0;
  padding: 0 25px;
  transition: all 0.2s ease;
}

.sidebar-widget.tags a:hover {
  color: #fff;
  background: var(--bg-color);
}

.sidebar-widget.schedule-widget {
  background: #f4f9fc;
  padding: 25px;
}

.sidebar-widget.schedule-widget ul li {
  padding: 10px 0px;
  border-bottom: 1px solid #eee;
}

.search-form {
  position: relative;
  top: 10px;
}

.search-form i {
  position: absolute;
  right: 15px;
  top: 35%;
}

.footer {
  padding-bottom: 10px;
}

.footer-bg {
  background: rgb(244, 249, 252);
  background: radial-gradient(
    circle,
    rgba(244, 249, 252, 1) 0%,
    rgba(241, 249, 253, 0.4069193293539326) 42%,
    rgba(229, 238, 245, 1) 100%
  );
}

.footer .copyright {
  font-weight: 600;
}

.ht {
  height: var(--image_height_dicom);
}

.va {
  vertical-align: middle;
}

.lh-35 {
  line-height: 35px;
}

.logo {
  font-weight: 600;
  letter-spacing: 1px;
}

.logo h3 {
  color: var(--bg-color);
}

.logo span {
  color: var(--bg-color);
}

.widget .divider {
  height: 3px;
}

.widget h4 {
  color: var(--bg-color);
}

.widget .footer-menu a {
  color: #6f8ba4;
}

.widget .footer-menu a:hover {
  color: #e12454;
}

.footer-contact-block span {
  font-weight: 400;
  color: #6f8ba4;
}

.footer-contact-block i {
  font-size: 20px;
}

.footer-line {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  padding-top: 5px;
}

.footer-underline {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding-bottom: 5px;
}

.footer-socials li a {
  width: 45px;
  height: 45px;
  background: #6f8ba4;
  color: #fff;
  display: inline-block;
  text-align: center;
  border-radius: 100%;
  padding-top: 12px;
}

.widget-contact h6 {
  font-weight: 500;
  margin-bottom: 18px;
}

.widget-contact h6 i {
  color: #e12454;
}

.subscribe {
  position: relative;
}

.subscribe .form-control {
  border-radius: 50px;
  height: 60px;
  padding-left: 25px;
  border-color: #eee;
}

.subscribe .btn {
  position: absolute;
  right: 6px;
  top: 6px;
}

.backtop {
  position: fixed;
  background: #ff0000;
  z-index: 9999;
  display: inline-block;
  right: 45px;
  width: 40px;
  height: 40px;
  bottom: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  border-radius: 5px;
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  cursor: pointer;
}

.reveal {
  transition: all 0.3s;
  cursor: pointer;
  opacity: 1;
}

.colfl0 {
  float: left;
}

.colfr0 {
  float: right;
}

.colfl {
  float: left;
  padding: 10px;
}

.colfc {
  /*float: center;*/
  padding: 10px;
}

.colfr {
  float: right;
  padding: 10px;
}

.border-dbt {
  border-top: 2px solid rgba(0, 0, 0, 0.05) !important;
}

.border-db {
  border: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.border-dot {
  border: 1px dotted rgba(128, 65, 240, 0.75) !important;
}

.border-active {
  border: 1px solid rgba(42, 138, 167, 1) !important;
}

.border-highlight {
  border: 1px solid rgba(255, 165, 0, 0.05) !important;
}

.border-dbr {
  border-right: 2px solid rgba(0, 0, 0, 0.05) !important;
}

.border-dbl {
  border-left: 2px solid rgba(0, 0, 0, 0.05) !important;
}

.border-dbb {
  border-bottom: 2px solid rgba(0, 0, 0, 0.05) !important;
}

.clear {
  clear: both;
}

.row:after .colfl:after .colfr:after .container:after .clear:after {
  content: "";
  display: table;
  clear: both;
}

.disabled {
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}
.center {
  text-align: center;
}

.table {
  overflow: auto;
}

.filter-green {
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg)
    brightness(118%) contrast(119%);
}

.filter-blue {
  filter: invert(44%) sepia(99%) saturate(345%) hue-rotate(147deg)
    brightness(88%) contrast(88%);
}

.filter-blue:hover {
  filter: invert(50%) sepia(25%) saturate(5646%) hue-rotate(1deg)
    brightness(105%) contrast(103%);
}

.filter-orange {
  filter: invert(50%) sepia(25%) saturate(5646%) hue-rotate(1deg)
    brightness(105%) contrast(103%);
}

.list {
  scroll-behaviour: smooth;
  overflow-y: scroll;
}
.editor {
  bordercolor: "white";
  padding: 0px;
  margin: 0px;
  color: var(--bg-color-read);
}

.disable-link {
  pointer-events: none;
}
.enable-link {
  cursor: pointer;
}

.options {
  margin: 10px;
}
