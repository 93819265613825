.appointment_card {
  border: 1px solid #c7c7c7;
  border-radius: 8px;
  padding: 1rem;
  margin-top: 0.4rem;
  .date {
    font-weight: 600;
    font-size: 40px;
  }
  .month {
    font-size: 14px;
    color: #585858;
  }
  .timing {
    margin-top: 0.4rem;
    font-weight: 600;
  }
  .meet_type {
    // margin-top: 0.8rem;
    font-weight: 600;
    font-size: 22px;
    color: #2a8aa7;
    text-transform: capitalize;
  }
  .address_label {
    font-size: 15px;
    color: #585858;
    font-weight: 700;
    text-transform: capitalize;
  }
  .address_full {
    font-size: 15px;
    color: #585858;
    margin-top: 0.2rem;
    font-weight: 500;
    text-transform: capitalize;
  }
  .view_details {
    font-weight: 600;
    font-size: 15px;
    color: #2a8aa7;
  }
}

.transparent_button {
  background: none;
  border: 1px solid black;
  border-radius: 16px;
  padding: 6px 10px;
  font-weight: 600;
  cursor: pointer;
}

.steps_box {
  margin-top: 1rem;
  .single_step {
    // margin-left: 10px;
    margin-right: 50px;
    .title {
      margin-top: 0.6rem;
      font-weight: 600;
    }
    .description {
      font-size: 14px;
    }
  }
}

// .step_complete {
//   border: 2px solid #2a8aa7;
//   width: 20px;
//   height: 20px;
//   border-radius: 20px;
//   padding: 4px;
//   .inner_circle {
//     width: 8px;
//     height: 8px;
//     background-color: #94c4d2;
//     border-radius: 20px;
//   }
// }
// .step_incomplete {
//   border: 2px solid #c7c7c7;
//   width: 20px;
//   height: 20px;
//   border-radius: 20px;
// }
// .connector {
//   flex-grow: 1; //fill the space
//   width: 10px;
//   content: "";
//   display: block;
//   height: 1px;
//   background-color: red;
// }

//   new style
.steps {
  .checkout {
    display: table;
    table-layout: fixed;
    width: 90%;
    .current {
      text-align: left;
      display: table-cell;
      padding-left: 0;
      position: relative;
      .inner_circle {
        width: 8px;
        height: 8px;
        background-color: #94c4d2;
        border-radius: 20px;
        display: block;
        top: 39px;
        position: relative;
        left: 6px;
        z-index: 4;
      }
      .title {
        margin-top: 4rem;
        font-weight: 600;
      }
      .description {
        font-size: 14px;
      }
      .index {
        position: relative;
        z-index: 3;
        padding-bottom: 15px;
      }

      &:before {
        z-index: 2;
        background: #fff;
        margin: 0 auto;
        display: block;
        width: 30px;
        height: 30px;
        content: "";
        text-align: left;
        float: left;
        position: relative;
        top: 33px;
        border-radius: 100%;
        border: 2px solid #2a8aa7;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        padding: 4px;
      }

      &:after {
        z-index: 1;
        content: "";
        border: 1px dashed rgba(135, 135, 135, 0.2);
        width: 100%;
        webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(180deg);
        display: block;
        position: absolute;
        top: 42px;
        right: -20px;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      .caption {
        text-transform: uppercase;
        margin-top: 5px;
        font-weight: 900;
      }
    }
    .complete {
      text-align: left;
      display: table-cell;
      padding-left: 0;
      position: relative;
      .bluetitle {
        font-weight: 600;
        margin-top: 4rem;
        color: #2a8aa7;
      }
      .description {
        font-size: 14px;
      }
      .greydescription {
        font-size: 14px;
        color: #8f8f8f;
      }
      &:before {
        z-index: 2;
        background: #2a8aa7;
        margin: 0 auto;
        display: block;
        width: 30px;
        height: 30px;
        content: "";
        text-align: left;
        float: left;
        position: relative;
        top: 26px;
        border-radius: 100%;
        border: 2px solid #2a8aa7;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        padding: 4px;
      }

      &:after {
        z-index: 1;
        content: "";
        border: 1px dashed rgba(135, 135, 135, 0.2);
        width: 100%;
        webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(180deg);
        display: block;
        position: absolute;
        top: 42px;
        right: -20px;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      .caption {
        text-transform: uppercase;
        margin-top: 5px;
        font-weight: 900;
      }
    }

    .not_current {
      text-align: left;
      display: table-cell;
      padding-left: 0;
      position: relative;
      .title {
        margin-top: 4rem;
        font-weight: 600;
      }
      .greytitle {
        margin-top: 4rem;
        color: #8f8f8f;
      }
      .bluetitle {
        font-weight: 600;
        margin-top: 4rem;
        color: #2a8aa7;
      }
      .description {
        font-size: 14px;
      }
      .greydescription {
        font-size: 14px;
        color: #8f8f8f;
      }
      .index {
        position: relative;
        z-index: 3;
        padding-bottom: 15px;
      }

      &:before {
        z-index: 2;
        background: #fff;
        margin: 0 auto;
        display: block;
        width: 30px;
        height: 30px;
        content: "";
        text-align: left;
        float: left;
        position: relative;
        top: 25px;
        border-radius: 100%;
        border: 2px solid #c7c7c7;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        padding: 4px;
      }

      &:after {
        z-index: 1;
        content: "";
        border: 1px dashed rgba(135, 135, 135, 0.2);
        width: 100%;
        webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(180deg);
        display: block;
        position: absolute;
        top: 42px;
        right: -20px;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      .caption {
        text-transform: uppercase;
        margin-top: 5px;
        font-weight: 900;
      }
    }
  }
  .container-fluid {
    max-width: 550px;
    overflow: hidden;
  }
}

.bordered_tab_boundary_grey {
  background: #f5f5f5;
  border: 1px solid #e8e8e8;
  display: flex;
  justify-content: space-between;
  width: 250px;
  padding: 25px 20px;
  border-radius: 8px;
  margin-top: 1rem;
}

.bordered_tab_boundary {
  border: 1px solid #c7c7c7;
  display: flex;
  justify-content: space-between;
  width: 250px;
  padding: 10px 20px;
  border-radius: 8px;
  margin-top: 1rem;
  cursor: pointer;
  .bordered_tab {
    border: none;
    cursor: pointer;
    text-align: left;
    display: block;
    color: #2a8aa7;
    font-size: 22px;
    background: none;
    font-size: 18px;
    font-weight: 600;
  }
  &:hover {
    transition: 0.5s ease-out;
    border: 1px solid #000;
  }
  &:active {
    border: 1px solid #2a8aa7;
    background: rgba(234, 244, 247, 0.5);
  }
}

.appointment_calendar {
  margin-top: 2rem;
  .react_calendar {
    width: 400px !important;
    max-width: 100% !important;
    font-family: "DM SANS";
    border-radius: 4px;
    border: 1px solid #c7c7c7;
    .react-calendar__navigation {
      font-size: 16px;
      border-bottom: 1px solid #c7c7c7;
      height: 40px;
      .react-calendar__navigation__prev2-button {
        display: none;
      }
      .react-calendar__navigation__next2-button {
        display: none;
      }
    }
    .react-calendar__viewContainer {
      .react-calendar__month-view {
        .react-calendar__tile {
          padding: 16px;
          color: #404040;
          font-size: 16px;
        }
        .react-calendar__tile--now {
          background: white;
          border: 1px solid #585858;
          color: #404040;
          font-weight: 700;
          border-radius: 40px;
        }
        .react-calendar__tile:disabled {
          background-color: transparent;
          color: #c7c7c7;
        }
        // .react-calendar__tile--active{}
      }
    }
  }
}

// filter
.filterBox {
  background: #fff;
  width: 300px;
  border-radius: 10px;
  position: absolute;
  top: 40px;
  box-shadow: 0px 4px 30px 0px rgba(202, 202, 202, 0.5);
  padding: 10px;
  z-index: 999;
}
