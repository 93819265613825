.greylabel {
  display: flex;
  cursor: pointer;
  border-radius: 20px;
  padding: 2px 15px 0px 6px;
  font-weight: 400;
  font-family: "DM SANS";
  width: fit-content;
  background: #e9e9e9;
}

.yellowlabel {
  display: flex;
  cursor: pointer;
  border-radius: 20px;
  padding: 2px 6px 0px 15px;
  font-weight: 400;
  font-family: "DM SANS";
  width: fit-content;
  background: #fff7d9;
}

.yellowlabel_right {
  display: flex;
  cursor: pointer;
  border-radius: 20px;
  padding: 2px 15px 0px 6px;
  font-weight: 400;
  font-family: "DM SANS";
  width: fit-content;
  background: #fff7d9;
}

.green_label {
  display: flex;
  width: fit-content;
  margin: 10px 10px 0 0;
  padding: 4px 8px 0px 8px;
  border-radius: 20px;
  font-weight: 500;
  background: rgba(229, 242, 223, 0.5);
}

.green_label_appointment {
  cursor: pointer;
  display: flex;
  width: fit-content;
  margin: 10px 10px 0 0;
  padding: 4px 8px;
  border-radius: 20px;
  font-weight: 500;
  background: #e5f2df;
}

.green_label_appointment_selected {
  cursor: pointer;
  display: flex;
  width: fit-content;
  margin: 10px 10px 0 0;
  padding: 4px 8px;
  border-radius: 20px;
  font-weight: 500;
  background: #52a72a;
  color: white;
}
/* dots */
.required_dot {
  height: 5px;
  width: 5px;
  background-color: #ff6d0d;
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 4px;
  display: inline-block;
}

.notify_dot {
  height: 7px;
  width: 7px;
  background-color: #ff6d0d;
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 7px;
  display: inline-block;
}

.yellow_dot {
  height: 7px;
  width: 7px;
  background-color: #ffc700;
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 7px;
  display: inline-block;
}
.green_dot {
  height: 7px;
  width: 7px;
  background-color: #52a72a;
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 7px;
  display: inline-block;
}
