.axisLCx line {
  stroke: #eeeeee;
}

.axisLCx path {
  stroke: gray;
}

.axisLCx text {
  fill: gray;
}

.axisLCy line {
  stroke: gray;
}

.axisLCy path {
  stroke: gray;
}

.axisLCy text {
  fill: gray;
}

.legendContainer {
  display: flex;
  justify-content: center;
}
