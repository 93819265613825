:root {
  /* Sizes */

  --top-bar-height: 40px;
  --top-bar-expanded-height: 160px;
  --toolbar-height: 78px;
  --toolbar-drawer-height: 62px;
  --left-sidepanel-menu-width: 160px;
  --right-sidepanel-menu-width: 320px;
  --study-list-padding: 8%;
  --study-list-padding-medium-screen: 10px;
  --snackbar-size: 244px;
  --left-sidepanel-menu-widthtest: 100px;

  /* Transitions */

  --transition-duration: 0.3s;
  --transition-effect: ease;
  --sidepanel-transition: all 0.3s ease;

  /* Thicknesses */

  --viewport-border-thickness: 1px;
  --ui-border-thickness: 1px;

  /** Colors **/

  --snackbar-success: rgba(94, 164, 0, 0.9);
  --snackbar-error: rgba(236, 61, 61, 0.9);
  --snackbar-warning: rgba(235, 173, 23, 0.9);
  --snackbar-info: rgba(54, 156, 199, 0.9);

  /** z-Index **/
  --snackbar-zIndex: 1075;

  /** DatePicker Colors **/
  --datePicker-input-background: #2c363f;
  --datePicker-picker-background: #9ccef9;
  --datePicker-picker-disabled: #d3d3d3;
  --datePicker-picker-text: var(--bg-color);
  --datePicker-picker-text-disabled: #aaa;
  --datePicker-picker-text-input: #fff;
}
