.meta__input__mobile {
  width: 100%;
  height: 45px;
  border-radius: 6px;
  border: 1px solid #c7c7c7;
  padding: 0 10px;
}
.meta__input__label {
  margin-top: 10px;
  font-size: 13px;
}
